<template>
  <div class="">
    <v-btn small color="primary" class="mr-1" @click="uploaddata = []; file = null; dialog = true">
      <v-icon class="mr-1">mdi-upload-multiple</v-icon>
      <span>Bulk Upload</span>
    </v-btn>
    <lb-dialog v-model="dialog" :width="(uploaddata.length > 0) ? '' : '400'"
      heading="Bulk Chart of Accounts Upload" :loading="loading">
      <template v-slot:body>
        <div>
          <div class="text-center mb-1">Upload the bulk upload chart of accounts template here.</div>
          <div class="d-flex align-center justify-center">
            <lb-file v-model="file" label="" class="py-0 my-0" :loading="fileprocessing['file'] === 1"
              :drag="false" hidedetails @change="readFile('file')" :displayname="false"
              accept=".xlsx,.xls,.xlsm,.xlsb" />
            <v-btn color="primary" small class="ml-2"
              @click="$nova.downloadFile('', {}, '/v2/fluxmanagement/masters/chartofaccount/getbulksubmittemplate')">Download
              Template</v-btn>
          </div>
        </div>
        <div v-if="uploaddata.length > 0" class="mt-3">
          <v-simple-table dense class="">
            <template v-slot:default>
              <thead>
                <th class="text-left" scope="glcode">GL Code</th>
                <th class="text-left" scope="name">Name</th>
                <th class="text-left" scope="type">Type</th>
                <th class="text-left" scope="category">Category</th>
                <th class="text-left" scope="subcategory">Sub Category</th>
                <th class="text-left" scope="subcategory">Preparer</th>
                <th class="text-left" scope="subcategory">Approver</th>
              </thead>
              <v-slide-y-transition class="" group tag="tbody" style="overflow:hidden !important;">
                <tr v-for="(v, k) in uploaddata" :key="k" class="">
                  <template v-for="(vv, kk) in v">
                    <td v-if="dataerror[k][kk]" :key="kk" class="error--text font-weight-bold">
                      <v-tooltip bottom content-class="tooltip-bottom">
                        <template v-slot:activator="{ on, attrs }">
                          <div v-if="kk === 'preparer' || kk === 'approver'" class="d-inline-block">
                            <v-chip v-for="(va, ka) in vv" :key="ka" v-bind="attrs" v-on="on" color="error" small outlined
                              class="pl-2 mb-1 mr-1">
                              <v-icon v-if="kk==='tags'">mdi-tag</v-icon>
                              <v-icon v-else>mdi-account</v-icon>
                              <span class="pl-1">{{ va }}</span>
                            </v-chip>
                          </div>
                          <span v-else v-bind="attrs" v-on="on">{{vv || 'Error'}}</span>
                        </template>
                        <span>{{ dataerror[k][kk] }}</span>
                      </v-tooltip>
                    </td>
                    <td v-else :key="kk">
                      <div v-if="kk === 'preparer' || kk === 'approver'">
                        <v-chip v-for="(va, ka) in vv" :key="ka" small outlined class="pl-2 mb-1 mr-1">
                          <v-icon v-if="kk==='tags'">mdi-tag</v-icon>
                          <v-icon v-else>mdi-account</v-icon>
                          <span class="pl-1">{{ va }}</span>
                        </v-chip>
                      </div>
                      <div v-else>{{ vv }}</div>
                    </td>
                  </template>
                </tr>
              </v-slide-y-transition>
            </template>
          </v-simple-table>
        </div>
      </template>
      <template v-slot:actions>
        <v-spacer></v-spacer>
        <div v-if="uploaddata.length > 0">
          <v-btn small class="ml-2" color="primary" v-if="!errorflag" @click="uploadData()">Verify &
            Upload</v-btn>
          <div class="ml-2 error--text font-weight-bold" v-else>We found few errors. Rectify the same to proceed to upload
          </div>
        </div>
      </template>
    </lb-dialog>
  </div>
</template>

<script>

export default {
  name: 'fluxmanagement_component_chartofaccount_bulkupload',
  // props: ['props'],
  data: function () {
    return {
      right: "fluxmanagement_master_chartofaccount",
      // generateddescription: "",
      file: null,
      fileprocessing: {},
      errorflag: false,
      uploaddata: [],
      dataerror: [],
      dialog: false,
      loading: false,
    }
  },
  created() {
  },
  activated() {
  },
  methods: {
    init() {
    },
    readFile(key) {
      let file = this.file;
      if ((file || {}).name && (this.fileprocessing[key] || 0) === 0) {
        let headings = ['GL Code', 'Name', 'Type', 'Category', 'Sub Category', 'Preparer', 'Approver'];
        let possibletypes = ['Asset', 'Equity', 'Liability', 'Income', 'Expense', 'Others'];
        let possibletypeslower = possibletypes.map(x => x.toLowerCase());
        this.loading = true;
        this.fileprocessing[key] = 1;
        let ext = ((file || {}).name || "").split(".").pop();
        Promise.resolve().then(() => {
          if (["xlsx", "xls", "xlsm", "xlsb"].indexOf(ext) === -1) throw new "Only xlsx,xls,xlsm,xlsb file extension supported";
          return this.$nova.readExcelFile(file, "TOP SHEET");
        }).then(dt => {
          if (JSON.stringify(dt[0] || []) === JSON.stringify(headings)) {
            let filedata = [];
            let errordata = [];
            this.errorflag = false;
            for (let i = 0; i < dt.length; i++) {
              if (i > 0) {
                const el = dt[i];
                let rowdata = { "glcode": el[0], "name": el[1], "type": el[2], "category": el[3], "subcategory": el[4]};
                rowdata.preparer = (el[5] || "").split(",").filter(x => x.replaceAll(/\s/g, ''));
                rowdata.approver = (el[6] || "").split(",").filter(x => x.replaceAll(/\s/g, ''));
                let errors = {};
                let typeindex = possibletypeslower.indexOf((rowdata.type || "").toLowerCase());
                if(typeindex > -1) rowdata.type = possibletypes[typeindex];
                else errors["type"] = "Not a valid type";
                if ((rowdata.glcode || "").toString() === "") errors["glcode"] = "GL Code is required";
                if ((rowdata.name || "").toString() === "") errors["name"] = "Name is required";
                if ((rowdata.name || "").toString() === "") errors["name"] = "Name is required";
                if ((rowdata.category || "").toString() === "") errors["category"] = "Category is required";
                if ((rowdata.subcategory || "").toString() === "") errors["subcategory"] = "Sub Category is required";
                if (rowdata.preparer.length > 0 && rowdata.approver.length > 0) {
                  let preparers = rowdata.preparer;
                  let approvers = rowdata.approver;
                  let duplicates = [];
                  for (const i of preparers) {
                    if (approvers.indexOf(i) > -1) duplicates.push(i);
                  }
                  if (duplicates.length > 0) errors["preparer"] = "'" + duplicates.join(",") + "' cannot be both preparer and approver";
                }
                if (Object.keys(errors).length > 0) this.errorflag = true;
                filedata.push(rowdata);
                errordata.push(errors);
              }
            }
            this.uploaddata = filedata;
            this.dataerror = errordata;
            this.dialog = true;
          } else throw Error("Columns '"+headings.join("','")+"' are required in the same order");
        }).catch(err => {
          this.$store.commit("sbError", err.message || err || "Unknown error!");
          console.log(err);
        }).finally(() => {
          this.file = null;
          this.fileprocessing[key] = 0;
          this.loading = false;
        });
      }
    },
    uploadData() {
      this.loading = true;
      this.axios.post("/v2/fluxmanagement/masters/chartofaccount/bulkadd", { data: this.uploaddata }).then(dt => {
        if (dt.data.status === "success") {
          this.dialog = false;
          this.$emit('reload');
          this.$store.commit("sbSuccess", "Chart of Accounts uploaded successfully");
        } else throw dt.data.message || "Error uploading Chart of Accounts";
      }).catch(err => {
        this.$store.commit("sbError", err.message || err || "Unknown error!");
        console.log(err);
      }).finally(() => {
        this.loading = false;
      })
    },
  },
  watch: {
  }
}
</script>
